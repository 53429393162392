/* src/CSS/Home.css */

.main-container {
  margin: 40px auto; /* Center the container horizontally */
  width: 80%;
}

.main-logo-container {
  display: flex;
  justify-content: center;
  height: 300px;
}

.car-wrapper {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center;
}

.car-container {
  background-color: white;
  margin: 15px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.342); /* Soft shadow effect */
  width: 400px; /* Set a fixed width for each car container */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.car-container:hover {
  transform: translateY(-5px); /* Lift the card slightly on hover */
}

.contact-container:hover {
  transform: translateY(-5px); /* Lift the card slightly on hover */
}

.car-image {
  width: 100%;
  height: 250px; /* Set a fixed height for all images */
  object-fit: cover; /* Maintain aspect ratio and cover the entire area */
  border-radius: 12px; /* Rounded corners for images */
}

.car-name {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  font-weight: bold;
}

.description-container {
  padding: 16px; /* Increased padding for better spacing */
  border-bottom: 2px solid #ccc;
  border-top: 2px solid #ccc;
  margin-bottom: 20px;
  margin-top: 20px;
}

.price-wrapper {
  display: flex;
  justify-content: center;
}

.price-container {
  padding: 12px;
  background-color: #f0f0f0; /* Light gray background for price container */
  border-radius: 20px;
  border: 2px solid #ccc;
}

.car-price {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}


.sold-price-wrapper {
  display: flex;
  justify-content: center;
}

.sold-price-container {
  padding: 12px;
  background-color: #f07474; /* Light gray background for price container */
  border-radius: 20px;
  border: 2px solid #ccc;
  padding-left: 20px;
  padding-right: 20px;
}

.sold-car-price {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}


.contact-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.contact-container {
  border-radius: 10px;
  background-color: #f0f0f0;
  margin: 20px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.342); /* Soft shadow effect */
  transition: transform 0.3s ease;

  text-decoration: none; /* Removes underline */
}

.contact {
  width: 100%;
  display: flex;
  font-size: 20px;
  font-style: bold;
  color: inherit; /* Inherits color from the parent element */
  text-decoration: none; /* Removes underline */
}


/* Media query for responsiveness */
@media (max-width: 768px) {
  .car-container {
    width: calc(50% - 30px); /* Two columns, minus margins */
  }
}

@media (max-width: 480px) {
  .car-container {
    width: calc(100% - 30px); /* One column, minus margins */
  }
}
