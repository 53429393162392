/* src/CSS/Team.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.team-main-container {
  margin: 40px auto; /* Center the container horizontally */
  width: 80%;
  flex-direction: row;
}

.team-logo-container {
  display: flex;
  justify-content: center;
  height: 300px;
}

.team-wrapper {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center;
  flex-direction: row;
}

.team-container {
  background-color: white;
  margin: 15px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.342); /* Soft shadow effect */
  width: 400px; /* Set a fixed width for each team container */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.team-container:hover {
  transform: translateY(-5px); /* Lift the card slightly on hover */
}

.team-image {
  width: 100%;
  height: 300px; /* Set a fixed height for images */
  object-fit: cover; /* Maintain aspect ratio and cover the entire area */
  border-radius: 12px; /* Rounded corners for images */
}

.team-description-container {
  width: 100%;
  padding: 16px; /* Increased padding for better spacing */
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
}

.team-name-container {
  font-size: 20px;
  font-weight: bold;
}

.team-title-container {
  padding-bottom: 5px;
}

.team-number-container {
  font-weight: bold;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .team-container {
    width: calc(50% - 30px); /* Two columns, minus margins */
  }
}

@media (max-width: 480px) {
  .team-container {
    width: calc(100% - 30px); /* One column, minus margins */
  }
}
