/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif; /* Example of using Google Fonts */
  background-color: #f9f9f9; /* Light gray background for the body */
  margin: 0; /* Ensure no margin on the body */
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: 
    linear-gradient(rgba(78, 84, 200, 0.9), rgba(143, 148, 251, 0.9)), /* Gradient overlay */
    url('./Images/background.jpg') center/cover fixed; /* Background image */
  overflow-x: hidden; /* Prevent horizontal scroll */
  position: relative; /* Ensure positioning context */
}

/* Overlay styles for the background */
.main::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)); /* White overlay */
  z-index: -1; /* Send the overlay to the background */
}

.nav-container {
  width: 100%;
  height: 60px; /* Increased height for better visibility */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-bottom: 1px solid #9B9B9B;
  font-family: 'Roboto', sans-serif; /* Example of using Google Fonts */
  position: fixed; /* Fixed position to stay at the top */
  top: 0; /* Align to the top of the viewport */
  z-index: 1000; /* Ensure above other content */
}

.nav-container div {
  margin: 0 20px; /* Space between menu items */
  padding: 10px 15px; /* Padding inside menu items */
  cursor: pointer; /* Cursor pointer for better UX */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}



.main-content {
  margin-top: 60px; /* Ensure content below the fixed navbar */
  flex: 1; /* Allow content to take remaining space */
  display: flex;
  flex-direction: column;
  align-items: center;
}
